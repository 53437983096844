import styles from "./increaseRevenue.module.scss";
import React, { useState } from "react";
import Desktop from "../../../../images/caseStudyDesktop.png";
import Tablet from "../../../../images/caseStudyTablet.png";
import Mobile from "../../../../images/caseStudyMobile.png";
import MarketUpdate from "../../modal/marketUpdate";
import { withNamespaces } from "react-i18next";
const IncreaseRevenue = props => {
  const [open, setOpen] = useState(false);
  const {t} = props;
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <h3>
            {t("So konnten wir den Altmetallerlös in einem Industriebetrieb um 15% steigern!")}
          </h3>
          <p>
          {t("Erfahren Sie, wie Schrott24 die Altmetall-Verwertung in einem Industriebetrieb optimiert hat!")}
          </p>
          <a
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("Case Study Download")}
          </a>
        </div>
        <div className={styles.imageContainer}>
          <picture>
            <source media="(max-width: 768px)" sizes="100px" srcset={Mobile} />
            <source media="(max-width: 1140px)" sizes="100px" srcset={Tablet} />
            <img src={Desktop} />
          </picture>
        </div>
      </div>

      <MarketUpdate
        open={open}
        setOpen={setOpen}
        url="https://metal.schrott24.de/l/898431/2021-03-05/4xh9"
      />
    </div>
  );
};

export default withNamespaces()(IncreaseRevenue);
