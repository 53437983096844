import React, { useContext } from "react";
import * as styles from "./groupLegal.module.scss";
import { withNamespaces } from "react-i18next";
import DomainContext from "../../context/DomainContext";
import moment from "moment";

const GroupLegal = props => {
  const currentYear = () => {
    return moment().year();
  };

  const { t } = props;
  const domain = useContext(DomainContext);
  return (
    <div className={styles.groupLegal}>
      <div className={styles.groupLegalLinksContainer}>
        <span>© {currentYear()} Schrott24</span>
        <div
          className={
            domain === "en" ? styles.groupLegalLinksEn : styles.groupLegalLinks
          }
        >
          <a
            href={
              domain === "en"
                ? "https://www.schrott24.de/impressum"
                : "/impressum"
            }
          >
            {t("Impressum")}
          </a>
          <a
            href={
              domain === "en"
                ? "https://www.schrott24.de/disclaimer"
                : "/disclaimer"
            }
          >
            {t("Disclaimer")}
          </a>
          <a href={domain === "en" ? "https://www.schrott24.de/agb" : "/agb"}>
            {t("AGB")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(GroupLegal);
