import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import styles from "./marketUpdatePopUp.module.scss";

import CloseIcon from "@material-ui/icons/Close";

const MarketUpdatePopUp = props => {
  const [visible, setVisible] = useState(false);
  const [url, setUrl] = useState("");
  const handleScroll = () => {
    window.removeEventListener("scroll", handleScroll);

    setTimeout(() => setVisible(true), 3000);
  }; 

  useEffect(() => {
    var arrUrl = window.location.origin.split(".");

    if (arrUrl[arrUrl.length-1]==="ch") {
      setUrl("https://www.schrott24.de/faq/marktupdate/");
   
    } else {
      setUrl(window.location.origin + "/faq/marktupdate/");
   
    }

    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={visible ? styles.popUpWrapper : styles.invisiblePopUp}>
      <CloseIcon
        className={styles.closeButton}
        onClick={() => {
          setVisible(false);
        }}
      />
      <div  className={styles.popUpGroup}>
        <h3>Industriepreis-Entwicklungen</h3>
        <p>
          Monatliches Marktupdate über die Preisentwicklungen der Altmetalle
          direkt ins Postfach!
        </p>
        <a href={url}>
          {" "}
          Jetzt kostenlos abonnieren
        </a>
      </div>
    </div>
  );
};

export default MarketUpdatePopUp;
