import React, { useContext ,useState, useEffect} from "react";
import DomainContext from "../../context/DomainContext";
import * as styles from "./metaloopBanner.module.scss";
import { StaticImage } from "gatsby-plugin-image";

import leftImage from "../../images/shape_left.svg";
import bannerText from "../../images/metaloopBannerText.svg";
import rightImage from "../../images/shape_right.svg";

const MetaloopBanner = () => {
  const [url, setUrl] = useState(
    "https://www.metaloop.com/de/?resource=schrott24"
  );

  useEffect(() => {
    if (typeof(window) !== undefined) {
      var isQA = window.location.href.includes("/qa.");

      if (isQA) {
        setUrl("https://qa.metaloop.com/de/?resource=schrott24");
      }
    }
  });

  const domain = useContext(DomainContext);
  return (
    <div className={styles.outsideMetaloopBanner} id="metaloopBanner">
      <img src={leftImage} />

      <div className={styles.insideMetaloopBanner}>
        <div>
          <span>METALOOP IST UNSER NEUES B2B-PORTAL!</span>
        </div>
        <div className={styles.curiousContainer}>
          <span>INTERESSIERT?</span>
          <div>
            <a href={url}>
              HIER GEHT'S LANG!
            </a>
            <div className={styles.styledUnderlineContainer}></div>
          </div>
        </div>
      </div>

      <img src={rightImage} />
    </div>
  );
};

export default MetaloopBanner;
